<template>
  <main>
    <div class="status_wrapper">
      <div class="achats">
        <div>L'opération est désormais terminée</div>
      </div>
    </div>
  </main>
</template>
<script>

export default {
  name: 'finished',
}
</script>